<template>
	<div>
		<div class="pd30">
			<div>
				<member-coupon ref="index" :member_id="member_id" @refreash="refreash"></member-coupon>
			</div>
			<div class="mt30">
				<member-coupon-logs ref="logs" :member_id="member_id"></member-coupon-logs>
			</div>
		</div>
	</div>
</template>

<script>
	import memberCoupon from './coupon/index.vue';
	import memberCouponLogs from './coupon/logs.vue';
	export default{
		props:{
			member_id:{
				type:Number,
				default:0
			}
		},
		components:{
			memberCoupon,
			memberCouponLogs,
		},
		data(){
			return{
				
			}
		},
		methods:{
			refreash(){
				this.$refs.index.getLists();
				this.$refs.logs.getLists();
			}
		}
	}
</script>

<style>
	
	
	
</style>
