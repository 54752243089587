<template>
	<div>
		<a-modal title="余额扣除" :width="480" :visible="visible"  @cancel="handleCancel" >
			<template slot="footer" >
				<a-button  key="back"  @click="handleCancel">
					取消
				</a-button>
				<a-button  key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					确定扣除
				</a-button>
			</template>
			<div>
				<a-spin :spinning="loading">
					<div class="flex center">
						<div class="detail-modal-balance-deduction">
							<div class="detail-modal-balance-deduction-header flex space alcenter">
								<div class="ft14 ftw600 cl-main">原本扣除金额</div>
								<div>
									<a-input-number  v-model="form.original_deduction"  :formatter="value => `¥${value}`" :precision="2" :min="0" />
								</div>
							</div>
							
							<div class="detail-modal-balance-deduction-content">
								<div class="flex space pt15">
									<div class="flex alcenter">
										<div class=" ft14 ftw600 cl-main">实际扣除金额</div>
										<!-- <div class="ft12 ftw400 cl-info ml4">(当前会员折扣7折)</div> -->
									</div>
									
									<!-- <div class="flex alcenter" @click="checkAct()" v-if="form.use_discount==1">
										<div  class="detail-modal-balance-deduction-content-check active" ><a-icon type="check" class="cl-w"/></div>
										<div class="ml4 cl-theme ft12 ftw400">使用折扣</div>
									</div>
									
									<div class="flex alcenter" @click="checkAct()" v-else>
										<div  class="detail-modal-balance-deduction-content-check"> </div>
										<div class="ml4 cl-info ft12 ftw400">使用折扣</div>
									</div> -->
									
									
								</div>
								<div style="position: relative;" class="mt15">
									<a-input-number  v-model="form.balance" :precision="2" :min="0" >
										
									</a-input-number>
									<div class="input-number-addon">¥</div>
								</div>
							</div>
							
							<div class="detail-modal-balance-deduction-footer flex space alcenter">
								<div class="flex alcenter">
									<div class="ft12 ftw400 cl-main">当前余额</div>
									<div class="ft16 ftw600 cl-black ml8">¥{{member.balance}}</div>
								</div>
								
								<div class="flex alcenter">
									<div class="ft12 ftw400 cl-main">扣除后剩余</div>
									<div class="ft16 ftw600 cl-orange ml8">¥{{remain_balance}}</div>
								</div>
							</div>
						</div>
					</div>
								
					<div class="flex center mt24">
						<div class="detail-modal-balance-deduction-info">
							<a-input style="width: 420px;" placeholder="如非会员本人消费，请备注好关系姓名" v-model="form.remarks" :maxLength="20">
								<div slot="prefix" class="input-addon-before">
									备注
								</div>
							</a-input>
						</div>
					</div>
				</a-spin>
			</div>
		</a-modal>
	</div>
</template>

<script>
	export default {
		props: {
			visible:{
				type:Boolean,
				default:false,
			},
			member_id:{
				type:Number,
				default:0
			}
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				member:{
					balance:0,
				},
				form: {
					original_deduction:0,
					balance:0,
					remarks:'',
					// use_discount:0,
				}
			}
		},
		computed:{
			remain_balance(){
				let balance = parseFloat(this.member.balance) * 100;
				let input_balance = parseFloat(this.form.balance == '' ? 0 : this.form.balance) * 100;
				return  ((balance - input_balance)/100).toFixed(2);
			}
		},
		created() {
			this.loaddata();
		},
		methods: {
			
			loaddata(){
				if(this.loading==true) return true;
				this.loading=true;
				this.$http.api('admin/showMember',{
					member_id:this.member_id
				}).then(res=>{
					this.member=res.detail;
					this.loading=false;
				}).catch(res=>{
					this.loading=false;
					this.$emit("cancel");
				})
			},
			
			// checkAct(){
			// 	if(this.form.use_discount==1){
			// 		this.form.use_discount=0;
			// 	}else{
			// 		this.form.use_discount=1;
			// 	}
			
			// },
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
	
			/**
			 * 提交数据
			 */
			onSubmit() {
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/balanceDeduction',{
					member_id:this.member_id,
					original_deduction:this.form.original_deduction,
					balance:this.form.balance,
					remarks:this.form.remarks,
				}).then(res=>{
					this.$message.success('扣除成功',1,()=>{
						this.confirmLoading=false;
						this.$emit("ok");
					})
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
				
			},
			
		}
	}
</script>

<style>
	.detail-modal-balance-deduction{
		width: 360px;
		height: 238px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #EBEDF5;
		padding: 0px 20px 0px 20px;
	}
	.detail-modal-balance-deduction-header{
		height: 64px;
		border-bottom: 1px solid #DCDFE6;
	}
	
	.detail-modal-balance-deduction-header .ant-input-number {
		font-size: 20px;
		line-height: 20px;
		border: none;
		font-weight: bold;
		color: #A6AEC2;
		height: 20px;
		width: 100px;
	}
	
	.detail-modal-balance-deduction-header .ant-input-number-input {
		width: 100px;
		height: 20px;
		border-radius: 4px;
	}
	
	.detail-modal-balance-deduction-header .ant-input-number-focused {
		box-shadow: none;
	}
	
	.detail-modal-balance-deduction-header .ant-input-number-handler-wrap {
		display: none;
	}
	
	.detail-modal-balance-deduction-content{
		height: 120px;
		border-bottom: 1px solid #DCDFE6;
	}
	
	.detail-modal-balance-deduction-content .ant-input-number {
		
		font-size: 40px;
		line-height: 50px;
		font-weight: bold;
		border: none;
		color: #A6AEC2;
		width: 300px;
		height: 50px;
		border-radius: 4px;
		
	}
	
	
	.detail-modal-balance-deduction-content .ant-input-number-input {
		width: 300px;
		height: 50px;
		padding: 0 59px;
		border-radius: 4px;
	}
	
	.detail-modal-balance-deduction-content .ant-input-number-handler-wrap {
		display: none;
	}
	
	.detail-modal-balance-deduction-content .ant-input-number-focused {
		box-shadow: none;
	}
	
	.detail-modal-balance-deduction-content .input-number-addon{
		height: 50px;
		padding-left: 20px;
		position: absolute;
		top: 0px;
		left: 0;
		text-align: right;
		line-height: 50px;
		
		font-size: 40px;
		font-weight: bold;
		color: #232529;
	}
	
	.detail-modal-balance-deduction-footer{
		height: 51px;
	}
	
	.detail-modal-balance-deduction-info .ant-input-affix-wrapper .ant-input-prefix {
	    position: absolute;
	    top: 50%;
	    z-index: 2;
	    display: flex;
	    align-items: center;
	    color: rgba(0, 0, 0, 0.65);
	    line-height: 0;
	    transform: translateY(-50%);
	    width: 35px;
	}
	
	.detail-modal-balance-deduction-info .ant-input-affix-wrapper .ant-input:not(:first-child) {
	    padding-left: 59px;
	}
	
	.detail-modal-balance-deduction-content-check{
		width: 16px;
		height: 16px;
		border: 1px solid #A6AEC2;
		line-height: 16px;
		text-align: center;
		font-size: 12px;
		cursor: pointer;
	}
	
	.detail-modal-balance-deduction-content-check.active{
		background: #4772FF;
	}
</style>
