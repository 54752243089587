<template>
	<div>
		<div >
			<div class="pd30">
				<div>
					<card-list :member_id="member_id"></card-list>
				</div>
				<div class="mt20">
					<record-list :member_id="member_id"></record-list>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import cardList from './oncecard/cardlist.vue';
	import recordList from './oncecard/recordlist.vue';
	export default{
		props:{
			member_id:{
				type:Number,
				default:0
			}
		},
		components:{
			cardList,
			recordList,
		},
		data(){
			return{
				loading:false,
			}
		}
	}
</script>

<style>
</style>
