<template>
	<div>
		<div class="pd30">
			<div class="wxb-table-gray">
				<a-table rowKey="buy_order_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
				 :loading="loading">
					<div class="text-center" slot="total_price" slot-scope="total_price,record">
						¥{{total_price}}
					</div>
					
					<div class="text-center" slot="price" slot-scope="price,record">
						¥{{price}}
					</div>
					
					<div class="text-center" slot="discount" slot-scope="discount,record">
						{{discount}}折(-¥{{record.vip_balance}})
					</div>
				</a-table>
			</div>
		</div>
	</div>
</template>

<script>
import {listMixin} from '@/common/mixin/list';
	export default{
		props:{
			member_id:{
				type:Number,
				default:0
			}
		},
		mixins:[listMixin],
		data(){
			return{
				loading:false,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				columns: [
					{title: '买单金额',dataIndex: 'balance',align: 'center',scopedSlots: {customRender: 'balance'}},
					{title: '实付金额',dataIndex: 'price',align: 'center',scopedSlots: {customRender: 'price'}},
					{title: '会员折扣',dataIndex: 'discount',align: 'center',scopedSlots: {customRender: 'discount'}},
					{title: '优惠券抵扣',dataIndex: 'coupon_money',align: 'center',ellipsis: true},
					{title: '积分抵扣',dataIndex: 'integral_balance',align: 'center',ellipsis: true},
					{title: '买单时间',dataIndex: 'add_time_format',align: 'center',ellipsis: true},
				],
				datas:[
					
				]
			}
		},
		methods:{
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getMemberBuyLogsList',{
					member_id:this.member_id,
					limit:this.pagination.pageSize,
					page:this.pagination.current,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>
</style>
