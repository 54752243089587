<template>
	<div>
		<a-modal title="指派顾问" :width="480" :visible="visible"  @cancel="handleCancel" :footer="null">
			<div>
				<a-spin :spinning="loading">
					<div>
						 <a-input-search placeholder="搜索员工姓名或手机号" v-model="search.keyword" style="width: 100%" @search="onSearch" />
					</div>
					<div class="mt16">
						<div class="flex wrap"  v-if="datas.length>0">
							<div class="assign-adviser-item" v-for="(item,index) in datas">
								<div class="flex space alcenter" style="width: 100%;">
									<div>
										<div class="ft16 ftw600 cl-main">{{item.name}}</div>
										<div class="ft12 ftw600 cl-info mt12">{{item.mobile}}</div>
									</div>
									<div>
										<a-button type="primary" @click="assignAct(index)">指派</a-button>
									</div>
								</div>
							</div>
						</div>
						
						<div v-else class="mt20 flex center">
							<a-empty/>
						</div>
						
						<div class="flex center mt20">
							<a-pagination @change="pageChange" :total="pagination.total" v-model="pagination.current" :pageSize="pagination.pageSize" show-less-items />
						</div>
					</div>
				</a-spin>
			</div>
		</a-modal>
	</div>
</template>

<script>
import {listMixin} from '@/common/mixin/list';
	export default {
		mixins:[listMixin],
		props: {
			visible:{
				type:Boolean,
				default:false,
			},
			member_id:{
				type:Number,
				default:0,
			},
			staff_id:{
				type:Number,
				default:0,
			}
		},
		data() {
			return {
				loading: false,
				pagination: {
					current: 1,
					pageSize: 4, //每页中显示10条数据
					total: 0,
				},
				search:{
					keyword:'',
				},
				datas:[],
			}
		},
		methods: {
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/chooseShopStaff',{
					staff_id:this.staff_id,
					keyword:this.search.keyword,
					type:'member_detail'
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
					this.$emit("cancel");
				})
			},
			onSearch(){
				this.getLists();
			},
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
	
			/**
			 * 提交数据
			 */
			assignAct(index) {
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/assignAdviser',{
					member_id:this.member_id,
					staff_id:this.datas[index].staff_id,
				}).then(res=>{
					this.$message.success('指派成功',1,()=>{
						this.loading=false;
						this.$emit("ok");
					})
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
				
			},
			
		}
	}
</script>

<style>
	.assign-adviser-item{
		width: 100%;
		padding: 20px;
		background: #FFFFFF;
		border-radius: 8px;
		border: 1px solid #EBEDF5;
		margin-bottom: 16px;
	}
</style>
