<template>
	<div>
		<div class="logs_title">优惠券记录</div>
		<div class="mt16">
			<div class="wxb-table-gray">
				<a-table rowKey="member_coupon_logs_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
				 :loading="loading">
					<div class="flex alcenter center" slot="name" slot-scope="record">
						{{record.membercoupon != null ? record.membercoupon.name : '-'}}
					</div>
					
					<div class="flex alcenter center" slot="coupon_money" slot-scope="record">
						{{record.membercoupon != null ? record.membercoupon.coupon_money : '-'}}
					</div>
					
				</a-table>
			</div>
		</div>
	</div>
</template>

<script>
import {listMixin} from '@/common/mixin/list';
	export default{
		props:{
			member_id:{
				type:Number,
				default:0
			}
		},
		mixins:[listMixin],
		data(){
			return{
				loading:false,
				columns: [
					{title: '类型',dataIndex: 'info',align: 'center',ellipsis: true},
					{title: '券名称',key: 'name',align: 'center',scopedSlots: {customRender: 'name'}},
					{title: '券面值',key: 'coupon_money',align: 'center',scopedSlots: {customRender: 'coupon_money'}},
					{title: '时间',dataIndex: 'add_time_format',align: 'center',ellipsis: true},
					{title: '操作人',dataIndex: 'operate_name',align: 'center',ellipsis: true},
				],
				datas:[],
			}
		},
		methods:{
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getMemberCouponLogsList',{
					member_id:this.member_id,
					limit:this.pagination.pageSize,
					page:this.pagination.current,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>
	.logs_title{
		height: 20px;
		line-height: 20px;
		padding-left: 10px;
		border-left: 4px solid #4772FF;
		
		font-size: 16px;
		font-weight: 600;
		color: #232529;
	}
</style>
