<template>
	<div>
		<a-spin :spinning="loading">
			<div v-if="datas.length>0">
				<div class="card-list-item" v-for="(order,index1) in datas">
					<div class="flex alcenter">
						<div class="ft14 ftw500 cl-black">{{order.name}}</div>
						<div class="ft14 ftw600 cl-theme">({{order.status_means}})</div>
					</div>
					<div class="mt12">
						<div class="flex alcenter wrap">
							<div class="flex alcenter">
								<div class="flex alcenter">
									<div class="ft12 ftw400 cl-info">实付款：</div>
									<div class="ft14 ftwblod cl-price">¥{{order.need_pay}}</div>
								</div>
								<div class="flex alcenter ft12 ftw400 cl-notice ml5">
									<div >原价：</div>
									<div class="text-line">¥{{order.total_price}}</div>
								</div>
							</div>
							
							<div class="flex alcenter ml30">
								<div class="ft12 ftw400 cl-info">有效期至：</div>
								<div class="ft14  cl-main">{{order.valid_end_time}}</div>
							</div>
							
							<div class="flex alcenter ml30">
								<div class="ft12 ftw400 cl-info">订单编号：</div>
								<div class="ft14  cl-main">{{order.member_once_card_id}}</div>
							</div>
							
							<div class="flex alcenter ml30">
								<div class="ft12 ftw400 cl-info">下单时间：</div>
								<div class="ft14  cl-main">{{order.add_time_format}}</div>
							</div>
							
							<div class="flex alcenter ml30">
								<div class="ft12 ftw400 cl-info">支付方式：</div>
								<div class="ft14  cl-main">{{order.pay_type_mean}}</div>
							</div>
							
							<div class="flex alcenter ml30">
								<div class="ft12 ftw400 cl-info">支付时间：</div>
								<div class="ft14  cl-main">{{order.pay_time_format}}</div>
							</div>
						</div>
					</div>
					
					<div class="mt16">
						<div class="flex wrap">
							<div class="sub-card-list-item" v-for="(item,index2) in order.items">
								<div class="flex space alcenter" style="width: 100%;">
									<div class="flex alcenter">
										<img :src="item.cover_img"/>
										<div class="ml10">
											<div class="ft14 ftw600 cl-black text-over4">{{item.abbreviation}}</div>
											<div class="mt15 ft12 ftw400 cl-notice text-over4">门市价：¥{{item.market_price}}</div>
											<div class="mt15 flex alcenter">
												<div class="flex alcenter">
													<div class="ft12 ftw400 cl-info text-over4">总次数：</div>
													<div class="ft14 ftw600 cl-black text-over4">{{item.total_num}}次</div>
												</div>
												
												<div class="flex alcenter ml20">
													<div class="ft12 ftw400 cl-info text-over4">剩余次数：</div>
													<div class="ft14 ftw600 cl-theme text-over4">{{item.remain_num}}次</div>
												</div>
											</div>
										</div>
									</div>
									
									<div class="flex alcenter">
										<div class="button-write-off text-over4" v-if="item.remain_num>0 && order.status_another==1" @click="writeOffAct(index1,index2)">核销</div>
										<div class="button-write-off-over text-over4" v-if="item.remain_num<=0 && order.status_another==1" >核销完</div>
										<div class="button-write-off-over text-over4" v-if="order.status_another==8" >已完成</div>
										<div class="button-write-off-over text-over4"  v-if="order.status_another==-1">已过期</div>
									</div>
								</div>
							</div>
							</div>
					</div>
				</div>
			</div>
			
			
			<div class="mt20 flex center" v-else>
				<a-empty/>
			</div>
						
			<div class="flex center mt20">
				<a-pagination @change="pageChange" :total="pagination.total" v-model="pagination.current" :pageSize="pagination.pageSize" show-less-items />
			</div>	
			<div v-if="writeOffLoading">
				<write-off :datas="item" :visible="writeOffLoading" @cancel="cancelWriteOff" @ok="okWriteOff"></write-off>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import writeOff from '../../../../../project/components/OrderOncecardDetail/modal/writeOff.vue';
  import {listMixin} from '@/common/mixin/list';
	export default{
		props:{
			member_id:{
				type:Number,
				default:0
			}
		},
		mixins:[listMixin],
		components:{
			writeOff,
		},
		data(){
			return{
				loading:false,
				writeOffLoading:false,
				item:{},
				pagination:{
					total:0,
					current:1,
					pageSize:2,
				},
				datas:[],
			}
		},
		methods:{
			
			getLists() {
				if (this.loading == true) return;
				this.loading = true;
				this.$http.api('admin/getMemberOnceCardList', {
					limit: this.pagination.pageSize,
					page: this.pagination.current,
					member_id: this.member_id,
				}).then(res => {
					this.pagination.total = res.total;
					this.datas = res.list;
					this.loading = false;
				}).catch(res => {
					this.loading = false;
				})
			},
			
			writeOffAct(index1,index2){
				this.item=this.datas[index1].items[index2];
				this.writeOffLoading=true;
			},
			cancelWriteOff(){
				this.writeOffLoading=false;
			},
			okWriteOff(){
				this.writeOffLoading=false;
				this.getLists();
			},
		}
	}
</script>

<style>
	
	.card-list-item{
		padding-bottom: 24px;
		border-bottom: 1px solid #EBEDF5;
		margin-bottom: 24px;
	}
	
	@media screen and (max-width: 1500px) {
		.sub-card-list-item{
			width: 99%;
			height: 116px;
			background: #F7F8FC;
			border-radius: 4px;
			margin-right: 1%;
			margin-bottom: 1%;
			padding: 20px;
			line-height: 1;
		}
	}
	
	
	@media screen and (min-width: 1501px) {
		.sub-card-list-item{
			width: 49%;
			height: 116px;
			background: #F7F8FC;
			border-radius: 4px;
			margin-right: 1%;
			margin-bottom: 1%;
			padding: 20px;
			line-height: 1;
		}
	}

	
	.sub-card-list-item img{
		width: 100px;
		height: 80px;
	}
	
	
	
</style>
