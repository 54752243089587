<template>
	<div>
		<a-modal title="新增预约" :width="540" :visible="visible" @cancel="handleCancel">
			<template slot="footer">
				<a-button key="back" @click="handleCancel">
					取消
				</a-button>
				<a-button key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					确认新增
				</a-button>
			</template>
			<div>
				<a-spin :spinning="loading">
					<a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
						<a-form-model-item label="选择服务人员" required>
							<div @click="chooseServicemanAct()" v-if="JSON.stringify(form.serviceman) == '{}'"
								class="choose-serviceman-box ml10 clickAct flex center alcenter cl-theme ft14 ftw600">
								选择<i class="iconfont iconicon_arrowr ft14 ml5" />
							</div>

							<div v-else class="choose-serviceman-box flex  space">
								<div class="flex alcenter">
									<img :src="form.serviceman.face" class="choose-serviceman-box-face" />
									<div class="ml10">
										<div class="ft16 ftw600 cl-main text-over4">{{form.serviceman.name}}</div>
										<div class="mt15 flex alcenter">
											<i class="iconfont iconcall_gray mr5 ft12 cl-notice" />
											<div class="cl-info ft12 ftw400 text-over4">{{form.serviceman.mobile}}</div>
										</div>
									</div>
								</div>
								<div class="flex alcenter cente ft14 ftw600 cl-theme clickAct"
									@click="chooseServicemanAct()">
									重新选择<i class="iconfont iconicon_arrowr ft14 ml5" />
								</div>
							</div>
						</a-form-model-item>

						<a-form-model-item label="选择服务" required>
							<div @click="chooseProjectAct()" v-if="JSON.stringify(form.project) == '{}'"
								class="choose-project-box ml10 clickAct flex center alcenter cl-theme ft14 ftw600">
								选择<i class="iconfont iconicon_arrowr ft14 ml5" />
							</div>

							<div v-else class="choose-project-box flex space">
								<div class="flex alcenter">
									<img :src="form.project.cover_img" class="choose-project-box-cover-img" />
									<div class="ml10">
										<div class="ft14 ftw400 cl-main text-over4">{{form.project.name}}</div>
										<div v-if="form.project.sku_id>0">
											<template v-for="(sku_item,sku_index) in form.project.skus">
												<template v-if="sku_item.project_sku_id == form.project.sku_id">
													<div class="ft12 ftw400  mt5 ">会员价格：¥{{sku_item.price}}</div>
													<div class="flex alcenter">
														<div class="ft12 ftw400   mt5">市场价：¥{{sku_item.market_price}}
														</div>
														<div class="ml10 ft12 ftw400">
															(已选规格：{{sku_item.name}})</div>
													</div>
												</template>
											</template>
										</div>
										<div v-else>
											<div class="ft12 ftw400  mt5 ">会员价格：¥{{form.project.price}}</div>
											<div class="flex alcenter">
												<div class="ft12 ftw400   mt5">市场价：¥{{form.project.market_price}}</div>
											</div>
										</div>
									</div>
								</div>

								<div @click="chooseProjectAct()"
									class="flex alcenter cente ft14 ftw600 cl-theme clickAct">
									重新选择<i class="iconfont iconicon_arrowr ft14 ml5" />
								</div>
							</div>
						</a-form-model-item>

						<a-form-model-item label="预约时间" required>
							<a-date-picker valueFormat="YYYY-MM-DD" v-model="form.day" />
							<div class="mt10">
								<select-time-range v-model="form.appoint_time"></select-time-range>
							</div>
						</a-form-model-item>

						<a-form-model-item label="联系人" required>
							<a-input v-model="form.contact_name" placeholder="请输入联系人" style="width: 200px;" class="">
							</a-input>
						</a-form-model-item>

						<a-form-model-item label="联系电话" required>
							<a-input v-model="form.contact_mobile" placeholder="请输入联系电话" style="width: 200px;" class="">
							</a-input>
						</a-form-model-item>

						<a-form-model-item label="上门地址" required>
							<a-input v-model="form.contact_address" placeholder="请输入上门地址" style="width: 400px;"
								class=""></a-input>
						</a-form-model-item>

						<a-form-model-item label="实付价格" required>
							<a-input-number v-model="form.need_pay" placeholder="请输入" :precision="0" :min="2"
								style="width: 200px;" />
						</a-form-model-item>
						
						<a-form-model-item required label="是否算合伙人业绩" >
							 <a-switch v-model="form.is_commission==1" @change="changeSwitch" />
						</a-form-model-item>

					</a-form-model>

					<div v-if="chooseServicemanVisible">
						<choose-serviceman v-model="form.serviceman" :visible="chooseServicemanVisible"
							@cancel="cancelChooseServiceman" @ok="okChooseServiceman"></choose-serviceman>
					</div>
					<div v-if="chooseProjectVisible">
						<choose-project v-model="form.project" :visible="chooseProjectVisible"
							@cancel="cancelChooseProject" @ok="okChooseProject"></choose-project>
					</div>
				</a-spin>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import selectTimeRange from '../../../../../../appointment/components/order/modal/addAppointment/selectTimeRange.vue';
	import chooseServiceman from '../../../../../../appointment/components/order/modal/addAppointment/modal/chooseServiceman.vue';
	import chooseProject from '../../../../../../appointment/components/order/modal/addAppointment/modal/chooseProject.vue';
	export default {
		props: {
			visible: {
				type: Boolean,
				default: false,
			},
			member_id:{
				type:Number,
				default:0,
			}
		},
		components: {
			selectTimeRange,
			chooseServiceman,
			chooseProject,
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				chooseServicemanVisible: false,
				chooseProjectVisible: false,
				labelCol: {
					span: 4
				},
				wrapperCol: {
					span: 20
				},
				form: {
					serviceman: {},
					project: {},
					day: '',
					appoint_time: [],
					contact_name: '',
					contact_mobile: '',
					contact_address: '',
					need_pay: '',
					is_commission:0,
				}
			}
		},
		methods: {
			//选择服务人员
			chooseServicemanAct() {
				this.chooseServicemanVisible = true;
			},
			cancelChooseServiceman() {
				this.chooseServicemanVisible = false;
			},
			okChooseServiceman() {
				this.chooseServicemanVisible = false;
			},

			//选择项目
			chooseProjectAct() {
				this.chooseProjectVisible = true;
			},
			cancelChooseProject() {
				this.chooseProjectVisible = false;
			},
			okChooseProject() {
				this.chooseProjectVisible = false;
				console.log(this.form.project);
			},


			changeAppointTime(value) {
				this.form.appoint_time = value;
			},
			
			changeSwitch(){
				this.form.is_commission=this.form.is_commission == 1 ? 0 :1;
			},

			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},

			/**
			 * 提交数据
			 */
			onSubmit() {
				
				if (JSON.stringify(this.form.project) == '{}') {
					this.$message.error('请选择项目');
					return;
				}

				if (!this.form.project.project_id) {
					this.$message.error('请选择项目');
					return;
				}
				
				if (this.form.project.sku_id<0) {
					this.$message.error('请选择项目');
					return;
				}
				let project=new Array;
				project.push({
					project_id:this.form.project.project_id,
					sku_id:this.form.project.sku_id
				});

				if (JSON.stringify(this.form.serviceman) == '{}') {
					this.$message.error('请选择服务人员');
					return;
				}

				if (!this.form.serviceman.serviceman_id) {
					this.$message.error('请选择服务人员');
					return;
				}
				

				if (this.loading == true) return;
				this.loading = true;
				this.$http.api('admin/saveAppointment', {
					member_id: this.member_id,
					project: project,
					serviceman_id: this.form.serviceman.serviceman_id,
					day: this.form.day,
					appoint_time: this.form.appoint_time,
					contact_name: this.form.contact_name,
					contact_mobile: this.form.contact_mobile,
					contact_address: this.form.contact_address,
					need_pay: this.form.need_pay,
					is_commission:this.form.is_commission,
				}).then(res => {
					this.$message.success('操作成功');
					this.$emit("ok");
					this.loading = false;
				}).catch(res => {
					console.log(res);
					this.loading = false;
				})
			},
		}
	}
</script>

<style>
	.choose-serviceman-box {
		width: 280px;
		height: 80px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #EBEDF5;
		padding: 10px;
		line-height: 1;
	}

	.choose-serviceman-box-face {
		width: 60px;
		height: 60px;
		border-radius: 2px;
	}

	.choose-project-box {
		width: 400px;
		height: 80px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #EBEDF5;
		padding: 10px;
		line-height: 1;
	}

	.choose-project-box-cover-img {
		width: 80px;
		height: 60px;
	}
</style>
