<template>
	<div>
		<a-modal title="设置次卡价格" :width="480" :visible="visible"  @cancel="handleCancel" >
			<template slot="footer" >
				<a-button  key="back"  @click="handleCancel">
					取消
				</a-button>
				<a-button  key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					确定
				</a-button>
			</template>
			<div>
				<div class="flex center">
					<div class="detail-modal-card-set-price">
						<div class="detail-modal-card-set-price-header flex space alcenter">
							<div class="ft14 ftw600 cl-main">次卡原价</div>
							<div class="ft14 ftw600 cl-main">¥{{getPrice}}</div>
						</div>
						
						<div class="detail-modal-card-set-price-content">
							<div class=" ft14 ftw600 cl-main">实际办理次卡价格</div>
							<div style="position: relative;" class="mt15">
								<a-input-number  v-model="form.price" :precision="2" :min="0"  :max="getPrice">
									
								</a-input-number>
								<div class="input-number-addon">¥</div>
							</div>
						</div>
						
						
					</div>
				</div>
			
			</div>
		</a-modal>
	</div>
</template>

<script>
	export default {
		props: {
			visible:{
				type:Boolean,
				default:false,
			},
			once_card:{
				type:Object,
				default:function(){
					return new Object
				}
			},
			member:{
				type:Object,
				default:function(){
					return new Object
				}
			},
		},
		computed:{
			getPrice(){
				if(this.once_card.is_flash_sale==1 && this.once_card.is_flash_sale_expire==0){
					return parseInt(this.once_card.flash_price);
				}else{
					return parseInt(this.once_card.price);
				}
			}
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				form: {
					price:0,
				}
			}
		},
		methods: {
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
	
			/**
			 * 提交数据
			 */
			onSubmit() {
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/applyOnceCard',{
					member_id:this.member.member_id,
					once_card_id:this.once_card.once_card_id,
					price:this.form.price,
					total_price:this.getPrice,
				}).then(res=>{
					this.$message.success('办理成功',1,()=>{
						this.confirmLoading=false;
						this.$emit("ok");
					})
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
				
			},
			
		}
	}
</script>

<style>
	.detail-modal-card-set-price{
		width: 360px;
		height: 182px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #EBEDF5;
		padding: 0px 20px 0px 20px;
	}
	.detail-modal-card-set-price-header{
		height: 56px;
		border-bottom: 1px solid #DCDFE6;
	}
	
	.detail-modal-card-set-price-content{
		height: 125px;
		padding-top: 15px;
	}
	
	.detail-modal-card-set-price-content .ant-input-number {
		
		font-size: 40px;
		line-height: 50px;
		font-weight: bold;
		border: none;
		color: #A6AEC2;
		width: 300px;
		height: 50px;
		border-radius: 4px;
		
	}
	
	
	.detail-modal-card-set-price-content .ant-input-number-input {
		width: 300px;
		height: 50px;
		padding: 0 59px;
		border-radius: 4px;
	}
	
	.detail-modal-card-set-price-content .ant-input-number-handler-wrap {
		display: none;
	}
	
	.detail-modal-card-set-price-content .ant-input-number-focused {
		box-shadow: none;
	}
	
	.detail-modal-card-set-price-content .input-number-addon{
		height: 50px;
		padding-left: 20px;
		position: absolute;
		top: 0px;
		left: 0;
		text-align: right;
		line-height: 50px;
		
		font-size: 40px;
		font-weight: bold;
		color: #232529;
	}
</style>
